<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
    no-caret
    right
    no-flip
  >
    <template v-slot:button-content>
      <i class="ki ki-bold-more-ver"></i>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover" style="width: 250px">
      <b-dropdown-text tag="div" class="navi-item">
        <router-link :to="'/licensesdetails/' + index.id" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon-file-1 text-success"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.DETAILS') }} </span>
        </router-link>

        <router-link :to="'/licensesupdate/' + index.id" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon-edit-1 text-warning"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.UPDATE') }}</span>
        </router-link>

        <div>
          <div>
            <div class="navi-link" id="show-btn" @click="showModal">
              <span class="navi-icon">
                <i class="flaticon-close text-danger"></i>
              </span>
              <span class="navi-text">O’chirish</span>
            </div>
            <b-modal ref="my-modal" hide-footer title="O’chirish">
              <div class="d-block text-center">
                <h3>O’chirish</h3>
              </div>
              <b-button
                class="mt-3"
                variant="outline-warning"
                block
                @click="hideModal"
                >Bekor qilish</b-button
              >
              <b-button
                class="mt-2"
                variant="outline-danger"
                block
                @click="deleteNom(index.id)"
                >O’chirish</b-button
              >
            </b-modal>
          </div>
        </div>

        <a href="#" @click="getExcel(index.id)" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-graph-1"></i>
          </span>
          <span class="navi-text">Export to Excel</span>
        </a>
      </b-dropdown-text>
    </div>
  </b-dropdown>
</template>

<style lang="scss" scoped>
.navi-link {
  cursor: pointer;
}

.btn_submit {
  background-color: #007bff;
  color: rgb(255, 255, 255) !important;
}
.headline__text {
  font-size: 1rem !important;
  color: #000;
  font-weight: bold;

  text-decoration: underline;
}
.v-application ul,
.v-application ol {
  padding: 0 !important;
}
.dropdown-menu {
  z-index: 80;
}
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem;
}
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import axios from 'axios'

export default {
  name: 'licensesAction',
  props: {
    index: Object
  },
  methods: {
    getExcel(val) {
      axios
        .get('trucks/driver_licenses/' + val + '/', {
          responseType: 'blob'
        })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel'
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'License')
          document.body.appendChild(link)
          link.click()
        })
    },
    deleteNom(tabid) {
      const data = {
        id: tabid,
        page: this.$route.params.id
      }
      this.$store.dispatch('deleteLicenses', data)
      this.$refs['my-modal'].hide()
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    }
  }
}
</script>
