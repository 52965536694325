<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">Mashina ma'lumotlari</h3>
            </div>
            <div class="card-button"></div>
          </div>

          <div class="card-body">
            <div class="d-flex flex-wrap">
              <div class="col-3">
                <div
                  v-if="getTruckDetails.truck_type_description !== undefined"
                >
                  <v-text-field
                    outlined
                    label="Nomi/model"
                    dense
                    disabled
                    :value="
                      getTruckDetails.truck_type_description.brand.name +
                      ' ' +
                      getTruckDetails.truck_type_description.model
                    "
                  >
                  </v-text-field>
                </div>
              </div>
              <div class="col-3">
                <v-text-field
                  outlined
                  label="Red raqam region"
                  dense
                  disabled
                  :value="getTruckDetails.reg_number_region"
                >
                </v-text-field>
              </div>
              <div class="col-3">
                <v-text-field
                  outlined
                  label="Reg raqam"
                  dense
                  disabled
                  :value="getTruckDetails.reg_number"
                >
                </v-text-field>
              </div>

              <div class="col-3">
                <v-text-field
                  outlined
                  dense
                  label="Kuzov raqami"
                  disabled
                  :value="getTruckDetails.kuzov_number"
                >
                </v-text-field>
              </div>

              <div class="col-3">
                <v-text-field
                  outlined
                  label="Kuzov turi"
                  dense
                  disabled
                  :value="getTruckDetails.kuzov_type"
                >
                </v-text-field>
              </div>

              <div class="col-3">
                <v-text-field
                  label="Sotib olingan sana"
                  outlined
                  dense
                  disabled
                  :value="getTruckDetails.bought_date | formatDate"
                >
                </v-text-field>
              </div>

              <div class="col-3">
                <v-text-field
                  outlined
                  label="Dvigatel raqami"
                  dense
                  disabled
                  :value="getTruckDetails.dvigatel_number"
                >
                </v-text-field>
              </div>

              <div class="col-3">
                <v-text-field
                  label="Status"
                  outlined
                  dense
                  disabled
                  :value="getTruckDetails.status"
                >
                </v-text-field>
              </div>
              <div class="col-3">
                <v-text-field
                  outlined
                  dense
                  disabled
                  label="Hujjatlar holati"
                  :value="getTruckDetails.is_normal_truck_documents"
                >
                </v-text-field>
              </div>
              <div
                class="col-3"
                v-if="getTruckDetails.driver_name !== undefined"
              >
                <v-text-field
                  outlined
                  dense
                  label="Haydovchi"
                  disabled
                  :value="getTruckDetails.driverfullname"
                >
                </v-text-field>
              </div>
            </div>
            <div>
              <b-tabs content-class="mt-3">
                <b-tab title="Mashina hujajtlari" active>
                  <!-- Texnik Kurik Hujjatlari -->
                  <div>
                    <v-card class="mx-auto" max-width="100%" outlined hover>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-title class="headline mb-1"
                            >Texnik Ko'rik Hujjatlari</v-list-item-title
                          >
                          <v-list-item-action-text class="text-right">
                            <div class="d-flex justify-content-end">
                              <div
                                v-if="
                                  getTruckDetails.trucktechmonitor_set_status
                                "
                              >
                                <h6>
                                  <b-badge variant="primary">Norma</b-badge>
                                </h6>
                              </div>
                              <div v-else>
                                <h6>
                                  <b-badge variant="danger"
                                    >Kamchilik bor</b-badge
                                  >
                                </h6>
                              </div>
                              <div class="ml-2">
                                <v-btn
                                  :to="
                                    /techmonitorcreate/ + this.$route.params.id
                                  "
                                  color="primary"
                                  small
                                  >Texosmotr+</v-btn
                                >
                              </div>
                            </div>
                          </v-list-item-action-text>
                          <v-list-item-subtitle>
                            <v-data-table
                              :headers="trucktechmonitor_set"
                              hide-default-footer
                              no-data-text="Malumot Kiritilmagan"
                              :items="getTruckDetails.trucktechmonitor_set"
                            >
                              <template v-slot:[`item.action`]="{ item }">
                                <TechMonitorAction
                                  :index="item"
                                ></TechMonitorAction>
                              </template>

                              <template v-slot:[`item.expires`]="{ item }">
                                {{ item.expires | formatDate }}
                              </template>
                              <template v-slot:[`item.monitored`]="{ item }">
                                {{ item.monitored | formatDate }}
                              </template>
                            </v-data-table>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </div>

                  <div class="border my-5 border-dark"></div>

                  <!-- Gaz uskuna Hujjatlari -->
                  <div>
                    <v-card class="mx-auto" max-width="100%" outlined hover>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-title class="headline mb-1"
                            >Gaz uskuna Hujjatlari
                          </v-list-item-title>
                          <v-list-item-action-text class="text-right">
                            <div class="d-flex justify-content-end">
                              <div
                                v-if="
                                  getTruckDetails.truckgasequipmentpermit_set_status
                                "
                              >
                                <h6>
                                  <b-badge variant="primary">Norma</b-badge>
                                </h6>
                              </div>
                              <div v-else>
                                <h6>
                                  <b-badge variant="danger"
                                    >Kamchilik bor</b-badge
                                  >
                                </h6>
                              </div>
                              <div class="ml-2">
                                <v-btn
                                  :to="
                                    /gasequipscreate/ + this.$route.params.id
                                  "
                                  color="primary"
                                  small
                                  >Gaz uskuna+</v-btn
                                >
                              </div>
                            </div>
                          </v-list-item-action-text>

                          <v-list-item-subtitle>
                            <v-data-table
                              :headers="truckgasequipmentpermit_set"
                              hide-default-footer
                              no-data-text="Malumot Kiritilmagan"
                              :items="
                                getTruckDetails.truckgasequipmentpermit_set
                              "
                            >
                              <template v-slot:[`item.action`]="{ item }">
                                <GasEquipsAction
                                  :index="item"
                                ></GasEquipsAction>
                              </template>

                              <template v-slot:[`item.monitored`]="{ item }">
                                {{ item.monitored | formatDate }}
                              </template>

                              <template v-slot:[`item.expires`]="{ item }">
                                {{ item.expires | formatDate }}
                              </template>
                            </v-data-table>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </div>

                  <div class="border my-5 border-dark"></div>
                  <!-- Reklama Ruhsatnomalari -->

                  <div>
                    <v-card class="mx-auto" max-width="100%" outlined hover>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-title class="headline mb-1"
                            >Reklama Ruhsatnomalari
                          </v-list-item-title>
                          <v-list-item-action-text class="text-right">
                            <div class="d-flex justify-content-end">
                              <div
                                v-if="
                                  getTruckDetails.truckadvertpermit_set_status
                                "
                              >
                                <h6>
                                  <b-badge variant="primary">Norma</b-badge>
                                </h6>
                              </div>
                              <div v-else>
                                <h6>
                                  <b-badge variant="danger"
                                    >Kamchilik bor</b-badge
                                  >
                                </h6>
                              </div>
                              <div class="ml-2">
                                <v-btn
                                  :to="
                                    /advertpermitscreate/ +
                                    this.$route.params.id
                                  "
                                  color="primary"
                                  small
                                  >Reklama ruhsatnoma+</v-btn
                                >
                              </div>
                            </div>
                          </v-list-item-action-text>
                          <v-list-item-subtitle>
                            <v-data-table
                              no-data-text="Malumot Kiritilmagan"
                              :headers="truckadvertpermit_set"
                              hide-default-footer
                              :items="getTruckDetails.truckadvertpermit_set"
                            >
                              <template v-slot:[`item.action`]="{ item }">
                                <AdvertPermitsAction
                                  :index="item"
                                ></AdvertPermitsAction>
                              </template>

                              <template v-slot:[`item.permitted`]="{ item }">
                                {{ item.permitted | formatDate }}
                              </template>

                              <template v-slot:[`item.expires`]="{ item }">
                                {{ item.expires | formatDate }}
                              </template>
                            </v-data-table>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </div>

                  <div class="border my-5 border-dark"></div>

                  <!-- Litsenziyalar -->

                  <div>
                    <v-card class="mx-auto" max-width="100%" outlined hover>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-title class="headline mb-1"
                            >Litsenziyalar
                          </v-list-item-title>
                          <v-list-item-action-text class="text-right">
                            <div class="d-flex justify-content-end">
                              <div
                                v-if="getTruckDetails.trucklisence_set_status"
                              >
                                <h6>
                                  <b-badge variant="primary">Norma</b-badge>
                                </h6>
                              </div>
                              <div v-else>
                                <h6>
                                  <b-badge variant="danger"
                                    >Kamchilik bor</b-badge
                                  >
                                </h6>
                              </div>
                              <div class="ml-2">
                                <v-btn
                                  :to="/licencescreate/ + this.$route.params.id"
                                  color="primary"
                                  small
                                  >Litsenziya+</v-btn
                                >
                              </div>
                            </div>
                          </v-list-item-action-text>
                          <v-list-item-subtitle>
                            <v-data-table
                              :headers="trucklisence_set"
                              hide-default-footer
                              no-data-text="Malumot Kiritilmagan"
                              :items="getTruckDetails.trucklisence_set"
                            >
                              <template v-slot:[`item.expires`]="{ item }">
                                <span
                                  v-if="item.expires < date2"
                                  class="bg-danger p-2 rounded text-white"
                                >
                                  {{ item.expires | formatDate }}
                                </span>
                                <span class="p-2" v-else>
                                  {{ item.expires | formatDate }}
                                </span>
                              </template>

                              <template v-slot:[`item.given`]="{ item }">
                                {{ item.given | formatDate }}
                              </template>

                              <template v-slot:[`item.start_date`]="{ item }">
                                {{ item.start_date | formatDate }}
                              </template>

                              <template v-slot:[`item.action`]="{ item }">
                                <licensesAction :index="item"></licensesAction>
                              </template>
                            </v-data-table>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </div>

                  <div class="border my-5 border-dark"></div>

                  <!-- Sugurta hujjatlari -->

                  <div>
                    <v-card class="mx-auto" max-width="100%" outlined hover>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-title class="headline mb-1"
                            >Sugurta hujjatlari
                          </v-list-item-title>
                          <v-list-item-action-text class="text-right">
                            <div class="d-flex justify-content-end">
                              <div
                                v-if="getTruckDetails.truckinsurance_set_status"
                              >
                                <h6>
                                  <b-badge variant="primary">Norma</b-badge>
                                </h6>
                              </div>
                              <div v-else>
                                <h6>
                                  <b-badge variant="danger"
                                    >Kamchilik bor</b-badge
                                  >
                                </h6>
                              </div>
                              <div class="ml-2">
                                <v-btn
                                  :to="
                                    /insuarencescreate/ + this.$route.params.id
                                  "
                                  color="primary"
                                  small
                                  >Sugurta hujjati+</v-btn
                                >
                              </div>
                            </div>
                          </v-list-item-action-text>
                          <v-list-item-subtitle>
                            <v-data-table
                              no-data-text="Malumot Kiritilmagan"
                              :headers="truckinsurance_set"
                              hide-default-footer
                              :items="getTruckDetails.truckinsurance_set"
                            >
                              <template v-slot:[`item.action`]="{ item }">
                                <InsuarencesAction
                                  :index="item"
                                ></InsuarencesAction>
                              </template>
                              <template v-slot:[`item.reg_date`]="{ item }">
                                {{ item.reg_date | formatDate }}
                              </template>
                              <template v-slot:[`item.expires`]="{ item }">
                                {{ item.expires | formatDate }}
                              </template>
                            </v-data-table>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </div>

                  <div class="border my-5 border-dark"></div>

                  <!-- Ishonchnomalar -->

                  <div>
                    <v-card class="mx-auto" max-width="100%" outlined hover>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-title class="headline mb-1"
                            >Ishonchnomalar
                          </v-list-item-title>
                          <v-list-item-action-text class="text-right">
                            <div class="d-flex justify-content-end">
                              <div
                                v-if="
                                  getTruckDetails.truckdrivepermit_set_status
                                "
                              >
                                <h6>
                                  <b-badge variant="primary">Norma</b-badge>
                                </h6>
                              </div>
                              <div v-else>
                                <h6>
                                  <b-badge variant="danger"
                                    >Kamchilik bor</b-badge
                                  >
                                </h6>
                              </div>
                              <div class="ml-2">
                                <v-btn
                                  :to="
                                    /driverpermitscreate/ +
                                    this.$route.params.id
                                  "
                                  color="primary"
                                  small
                                  >Ishonchnoma+</v-btn
                                >
                              </div>
                            </div>
                          </v-list-item-action-text>
                          <v-list-item-subtitle>
                            <v-data-table
                              :headers="truckdrivepermit_set"
                              hide-default-footer
                              no-data-text="Malumot Kiritilmagan"
                              :items="getTruckDetails.truckdrivepermit_set"
                            >
                              <template v-slot:[`item.driver_name`]="{ item }">
                                <span>{{ item.driver_name }}</span>
                              </template>

                              <template v-slot:[`item.reg_date`]="{ item }">
                                {{ item.reg_date | formatDate }}
                              </template>
                              <template v-slot:[`item.expires`]="{ item }">
                                {{ item.expires | formatDate }}
                              </template>
                              <template v-slot:[`item.action`]="{ item }">
                                <DriveAction :index="item"></DriveAction>
                              </template>
                            </v-data-table>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import TechMonitorAction from './TechMonitor/TechMonitorAction'
import GasEquipsAction from './GazEquips/GasEquipsAction'
import AdvertPermitsAction from './AdvertPermits/AdvertPermitsAction'
import licensesAction from './Licenses/licensesAction'
import InsuarencesAction from './Insuarences/InsuarencesAction'
import DriveAction from './DrivePermits/DriveAction'

export default {
  components: {
    TechMonitorAction,
    GasEquipsAction,
    AdvertPermitsAction,
    licensesAction,
    InsuarencesAction,
    DriveAction
  },
  data() {
    return {
      hover: true,
      date2: new Date().toJSON().slice(0, 10).replace(/-/g, '-'),
      trucktechmonitor_set: [
        {
          text: 'Id',
          value: 'id'
        },
        {
          text: 'Reg raqami',
          value: 'reg_number'
        },
        {
          text: 'Tekshiruv sanasi',
          value: 'monitored'
        },
        {
          text: 'Tekshiruvchi',
          value: 'monitored_by'
        },

        {
          text: 'Tugash sanasi',
          value: 'expires'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ],
      truckgasequipmentpermit_set: [
        {
          text: 'Id',
          value: 'id'
        },
        {
          text: 'Reg Raqami',
          value: 'reg_number'
        },
        {
          text: 'Tekshiruv sanasi',
          value: 'monitored'
        },
        {
          text: 'Tugash sanasi',
          value: 'expires'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ],
      truckadvertpermit_set: [
        {
          text: 'Id',
          value: 'id'
        },
        {
          text: 'Reg raqami',
          value: 'reg_number'
        },
        {
          text: 'Berilgan sana',
          value: 'permitted'
        },
        {
          text: 'Tugash sanasi',
          value: 'expires'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ],
      trucklisence_set: [
        {
          text: 'Id',
          value: 'id'
        },
        {
          text: 'Turi',
          value: 'licence_type.name'
        },
        {
          text: 'Reg raqami',
          value: 'reg_number'
        },
        {
          text: 'Berilgan sana',
          value: 'given'
        },
        {
          text: 'Boshlanish sanasi',
          value: 'start_date'
        },
        {
          text: 'Berilgan',
          value: 'given_by'
        },
        {
          text: 'Tugash sanasi',
          value: 'expires'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ],
      truckinsurance_set: [
        {
          text: 'Id',
          value: 'id'
        },
        {
          text: 'Reg raqami',
          value: 'reg_number'
        },
        {
          text: 'Berilgan',
          value: 'given_by'
        },
        {
          text: 'Berilgan sanasi',
          value: 'reg_date'
        },
        {
          text: 'Tugash sanasi',
          value: 'expires'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ],
      truckdrivepermit_set: [
        {
          text: 'Id',
          value: 'id'
        },

        {
          text: 'Haydovchi',
          value: 'driver_description'
        },
        {
          text: 'Berilgan sanasi',
          value: 'reg_date'
        },
        {
          text: 'Tugash sanasi',
          value: 'expires'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('truckListall', this.$route.params.id)
  },
  computed: {
    getTruckDetails() {
      const data = this.$store.state.requests.truckListallstore
      if (data.trucktechmonitor_set !== undefined) {
        if (data.trucktechmonitor_set.length)
          data.trucktechmonitor_set_status = false
        for (let i = 0; i < data.trucktechmonitor_set.length; i++) {
          if (data.trucktechmonitor_set[i].expires < this.date2) {
            data.trucktechmonitor_set_status = false
          } else {
            data.trucktechmonitor_set_status = true
            break
          }
        }
        if (data.truckgasequipmentpermit_set.length)
          data.truckgasequipmentpermit_set_status = false
        for (let i = 0; i < data.truckgasequipmentpermit_set.length; i++) {
          if (data.truckgasequipmentpermit_set[i].expires < this.date2) {
            data.truckgasequipmentpermit_set_status = false
          } else {
            data.truckgasequipmentpermit_set_status = true
            break
          }
        }
        if (data.truckadvertpermit_set.length)
          data.truckadvertpermit_set_status = false
        for (let i = 0; i < data.truckadvertpermit_set.length; i++) {
          if (data.truckadvertpermit_set[i].expires < this.date2) {
            data.truckadvertpermit_set_status = false
          } else {
            data.truckadvertpermit_set_status = true
            break
          }
        }
        if (data.trucklisence_set.length)
          for (let i = 0; i < data.trucklisence_set.length; i++) {
            console.log(data.trucklisence_set[i].expires)
            if (this.date2 > data.trucklisence_set[i].expires) {
              console.log('false')
              data.trucklisence_set_status = false
            } else {
              console.log('true')
              data.trucklisence_set_status = true
            }
          }
        if (data.truckinsurance_set.length)
          data.truckinsurance_set_status = false
        for (let i = 0; i < data.truckinsurance_set.length; i++) {
          if (data.truckinsurance_set[i].expires < this.date2) {
            data.truckinsurance_set_status = false
          } else {
            data.truckinsurance_set_status = true
            break
          }
        }
        // data.truckdrivepermit_set.forEach(element => {
        for (let i = 0; i < data.truckdrivepermit_set.length; i++) {
          if (data.truckdrivepermit_set[i].expires < this.date2) {
            data.truckdrivepermit_set_status = false
          } else {
            data.truckdrivepermit_set_status = true
            break
          }
        }
      }
      return data
    }
  },
  filters: {
    formatDate: function (val) {
      if (val !== null && val !== undefined) {
        return val.split('-').reverse().join('-')
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Transport' },
      { title: 'Mashina malumotlari' }
    ])
  }
}
</script>

<style scoped>
.date__badge {
  padding: 10px;
  color: #fff;
  border-radius: 7px;
  background-color: #dc3545;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
  min-height: 200px;
}
</style>
